<template>
    <div v-if="info && cid == 4">
        <div class="approval-detail-title">
            <span class="approval-detail-tip">组长单位：</span>
            <span class="approval-detail-content">{{ info.group_company || '--'}}</span>
        </div>
        <div class="approval-detail-title">
            <span class="approval-detail-tip">中心数量：</span>
            <span class="approval-detail-content">{{ info.center_num === 1 ? '单中心' : '多中心' }}</span>
        </div>
        <div class="approval-detail-title">
            <span class="approval-detail-tip">是否需要伦理审批：</span>
            <span class="approval-detail-content">{{ info.is_ethical_approval === 1 ? '需要' : '不需要'
                }}</span>
        </div>
        <div class="approval-detail-title">
            <span class="approval-detail-tip">是否需要人遗办审批：</span>
            <span class="approval-detail-content">{{ info.is_human_heritage_approval === 1 ? '需要' : '不需要'
                }}</span>
        </div>
        <div class="approval-detail-title">
            <span class="approval-detail-tip">是否购买试验保险：</span>
            <span class="approval-detail-content">{{ info.is_test_insurance === 1 ? '需要购买' : '不需要购买' }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectInfo",
    data() {
        return {};
    },
    props: {
        cid: {
            type: [Number, String],
            default: ''
        },
        info: {
            type: [Object, String],
            default: ''
        },
    },
    methods: {}
};
</script>

<style scoped lang="scss">
.approval-detail-title {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;

    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;
        text-align: right;
        min-width: 120px;
        &.red {
            color: red;
        }
    }

    .approval-detail-content {
        flex: 7;
        flex-shrink: 1;
        color: #333333;

        &.red {
            color: red;
        }
    }
}
</style>
